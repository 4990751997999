import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default function createStore() {
	return new Vuex.Store({
		state: {
			host: '',
			books: [],
			// deviceType: 'pc',
			deviceType: 'mobile',
			showDebug: false,
			ads: {
				scriptUrl: 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-4688525518274277',
				home_insert: [
					{ class: 'adsbygoogle', style: 'display:block', 'data-ad-client': 'ca-pub-4688525518274277', 'data-ad-slot': '9170554297', 'data-ad-format': 'auto', 'data-full-width-responsive': 'true' },
					{ class: 'adsbygoogle', style: 'display:block', 'data-ad-client': 'ca-pub-4688525518274277', 'data-ad-slot': '7857472625', 'data-ad-format': 'auto', 'data-full-width-responsive': 'true' },
					{ class: 'adsbygoogle', style: 'display:block', 'data-ad-client': 'ca-pub-4688525518274277', 'data-ad-slot': '4513123989', 'data-ad-format': 'auto', 'data-full-width-responsive': 'true' },
				],
				home_welcome: { class: 'adsbygoogle', style: 'display:block', 'data-ad-client': 'ca-pub-4688525518274277', 'data-ad-slot': '1870648774', 'data-ad-format': 'auto', 'data-full-width-responsive': 'true' },
				detail_insert: [
					{ class: 'adsbygoogle', style: 'display:block', 'data-ad-client': 'ca-pub-4688525518274277', 'data-ad-slot': '9170554297', 'data-ad-format': 'auto', 'data-full-width-responsive': 'true' },
					{ class: 'adsbygoogle', style: 'display:block', 'data-ad-client': 'ca-pub-4688525518274277', 'data-ad-slot': '7857472625', 'data-ad-format': 'auto', 'data-full-width-responsive': 'true' },
					{ class: 'adsbygoogle', style: 'display:block', 'data-ad-client': 'ca-pub-4688525518274277', 'data-ad-slot': '4513123989', 'data-ad-format': 'auto', 'data-full-width-responsive': 'true' },
				],
				blog: { class: 'adsbygoogle', style: 'display:block', 'data-ad-client': 'ca-pub-4688525518274277', 'data-ad-slot': '1870648774', 'data-ad-format': 'auto', 'data-full-width-responsive': 'true' },
			},
			adPosConfig: {
				homeInsert: [6, 24],
				detailInsert: [2, 7, 12],
			},
			adsensConfig: {},
			domainConfig: {
				icon: '/icon/1.png',
				IAmTitle: 'Online Bible Reading',
				IAMEMAIL: 'biblereading.support@gmail.com',
				IAmURL: 'biblereading.one',

				ABOUTUS: 'Explore the Bible online with easy access to scriptures, study tools, and daily reading plans for spiritual growth.',
				meta: {
					title: 'Online Bible Reading',
					des: 'Access the Bible anytime with biblereading.one. Enjoy seamless navigation for study and reflection.',
				},

				home: {
					title: 'Online Bible Study and Daily Reading Plans',
					des: 'Access the Bible anytime with biblereading.one. Enjoy seamless navigation for study and reflection.',
				},
				welcome: {
					title: 'Welcome to biblereading.one!',
					des: 'Your online resource for exploring the Bible. Access scriptures, study tools, and personalized reading plans to deepen your faith and understanding.',
				},
				how: {
					title: 'How to use biblereading.one?',
					des: 'Navigate our website to find various Bible versions, study tools, and reading plans. Use the search feature for specific scriptures or topics. Join our community for daily inspiration and guidance.',
				},

				firebase: {
					apiKey: 'AIzaSyCHu4Quq9SMB6lFMekleHi9pJYJWoQDEbQ',
					authDomain: 'webs-9476a.firebaseapp.com',
					projectId: 'webs-9476a',
					storageBucket: 'webs-9476a.firebasestorage.app',
					messagingSenderId: '96762571859',
					appId: '1:96762571859:web:55c773233b6d92b058aaa6',
					measurementId: 'G-N25B6GBL91',
				},
				ads: 'google.com, pub-8158555231596181, DIRECT, f08c47fec0942fa0',
				adsense: {
					scriptUrl: 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8158555231596181',
				},
			},
		},
		mutations: {
			setHost(state, host) {
				state.host = host
			},
			setTitle(state, title) {
				state.title = title
			},
			setDomain(state, domainConfig) {
				state.domainConfig = domainConfig
			},
			setDeviceType(state, deviceType) {
				state.deviceType = deviceType
			},
			setDebug(state, showDebug) {
				state.showDebug = showDebug
			},
			setAdsense(state, adsenseConfig) {
				state.adsensConfig = adsenseConfig
			},
			setBooks(state, books) {
				state.books = books
			},
		},
		getters: {
			showDebug: (state) => state.showDebug,
			deviceType: (state) => state.deviceType,
			host: (state) => state.host,
			adsensConfig: (state) => state.adsensConfig,
			adPosConfig: (state) => state.adPosConfig,
			domainConfig: (state) => state.domainConfig,
		},
	})
}
